import React, { useContext } from 'react'

export type SubTheme =
  | 'white'
  | 'off-black'
  | 'black'
  | 'midnight-blue'
  | 'midnight-purple'
  | 'lime'
  | 'blue'

export const subThemeMap: Record<
  SubTheme,
  { primary: string; secondary: string; background: string }
> = {
  white: {
    primary: 'text-black',
    secondary: 'text-blue',
    background: 'bg-white',
  },
  'off-black': {
    primary: 'text-soft-pink',
    secondary: 'text-red',
    background: 'bg-off-black',
  },
  black: {
    primary: 'text-yellow',
    secondary: 'text-red',
    background: 'bg-black',
  },
  'midnight-blue': {
    primary: 'text-mint',
    secondary: 'text-pink',
    background: 'bg-blue',
  },
  'midnight-purple': {
    primary: 'text-lime',
    secondary: 'text-peach',
    background: 'bg-midnight-purple',
  },
  lime: {
    primary: 'text-midnight-blue',
    secondary: 'text-black',
    background: 'bg-lime',
  },
  blue: {
    primary: 'text-soft-purple',
    secondary: 'text-turqoise',
    background: 'bg-blue',
  },
}

const asd = 'bg-yellow bg-midnight-blue'

type ISubThemeContext = {
  subTheme: SubTheme
}
const SubThemeContext = React.createContext<ISubThemeContext>({
  subTheme: 'white',
})

export const useSubTheme = () => {
  const { subTheme } = useContext(SubThemeContext)
  return { ...subThemeMap[subTheme], subTheme }
}

export const SubThemeProvider: React.FC<{ subTheme: SubTheme }> = ({ subTheme, children }) => {
  return (
    <SubThemeContext.Provider value={{ subTheme: subTheme }}>{children}</SubThemeContext.Provider>
  )
}
