module.exports = {
  content: [
    './pages/**/*.{js,ts,jsx,tsx}',
    './components/**/*.{js,ts,jsx,tsx}',
    './themes/subtheme.tsx',
  ],
  theme: {
    screens: {
      sm: '480px',
      md: '768px',
      lg: '976px',
      xl: '1440px',
    },
    colors: {
      peach: '#E5AB8A',
      lime: '#CFF469',
      'midnight-purple': '#503658',
      turqoise: '#9BEFE2',
      'soft-purple': '#C0AFD0',
      blue: '#321FDC',
      pink: '#F36EBD',
      mint: '#ABEDBB',
      'midnight-blue': '#22306D',
      'soft-pink': '#F8D4D7',
      white: '#FFFFFF',
      red: '#E91229',
      yellow: '#FFF386',
      gray2: '#707787',
      'blue-tint': '#F5F4FF',
      black: '#000000',
      'off-black': '#1A1C21',
    },
    fontSize: {
      // standard
      h1: ['4rem', { letterSpacing: '-0.03em', lineHeight: '1.2em' }],
      h2: ['2.5rem', { letterSpacing: '-0.03em', lineHeight: '1.2em' }],
      'h3-tight': ['1.5rem', { letterSpacing: '-0.03em', lineHeight: '1.2em' }],
      h3: ['1.5rem', { letterSpacing: '0.0em', lineHeight: '1.2em' }],
      body1: ['18px', { letterSpacing: '-0.02em', lineHeight: '1.2em' }],
      'body-old-1': ['16px', { letterSpacing: '-0.02em' }],
      body2: ['16px', { letterSpacing: '-0.02em', lineHeight: '1.2em' }],
      // titles
      title: [
        '5rem',
        {
          letterSpacing: '-0.05em',
          lineHeight: '5.75rem',
        },
      ],
      subtitle: [
        '4rem',
        {
          letterSpacing: '-0.05em',
          lineHeight: '4rem',
        },
      ],
      subheading: [
        '2.5rem',
        {
          letterSpacing: '-0.05em',
          lineHeight: '3rem',
        },
      ],
    },
    fontFamily: {
      sans: ['Inter', 'sans-serif'],
      serif: ['Times New Roman', 'serif'],
      menlo: ['Menlo', 'sans-serif'],
    },
    extend: {
      spacing: {
        128: '32rem',
        144: '36rem',
      },
      borderRadius: {
        '4xl': '2rem',
      },
    },
  },
  plugins: [],
}
