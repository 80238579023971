import React from 'react'
import Discord from './discord'
import OpenSea from './opensea'
import Twitter from './twitter'

const Socials = () => {
  return (
    <div className={`flex flex-row gap-2 h-4`}>
      <a href="https://twitter.com/CryptoWrapped" target="_blank" rel="noopener noreferrer">
        <Twitter width="24px" height="24px" />
      </a>
      <a href="https://discord.gg/W3EueUWYFV" target="_blank" rel="noopener noreferrer">
        <Discord width="24px" height="24px" />
      </a>
      <a
        href="https://opensea.io/collection/crypto-wrapped-2021"
        target="_blank"
        rel="noopener noreferrer"
      >
        <OpenSea width="24px" height="24px" />
      </a>
    </div>
  )
}

export default Socials
