import { ellipseAddress } from 'blockchain/utils'
import { useRouter } from 'next/router'
import React from 'react'
import { useState } from 'react'
import { useWeb3Context } from '../../blockchain/Web3Context'

export const WalletDropdown: React.FC<{ closeMenu?: () => void }> = ({ closeMenu }) => {
  const { isConnected, address, ens, connectWallet, disconnectWallet } = useWeb3Context()

  const router = useRouter()
  const [showConfirm, setshowConfirm] = useState(false)
  const openConfirm = () => setshowConfirm(true)

  const handleConnect = async () => {
    await connectWallet()
    closeMenu?.()
  }

  const handleDisconnect = async () => {
    disconnectWallet()
    setshowConfirm(false)
    router.push('/')
    closeMenu?.()
  }

  if (!isConnected) {
    return (
      <div className="font-menlo font-bold cursor-pointer">
        <div onClick={handleConnect}>
          <a>CONNECT WALLET</a>
        </div>
      </div>
    )
  }
  return (
    <div className="font-menlo font-bold">
      <div style={{ cursor: 'pointer' }} onClick={showConfirm ? handleDisconnect : openConfirm}>
        {showConfirm ? 'Disconnect?' : ens ?? ellipseAddress(address)}
      </div>
    </div>
  )
}
