import { NavBar } from 'components/NavBar'
import Head from 'next/head'
import { SubTheme, subThemeMap, SubThemeProvider } from 'themes/subtheme'
import Socials from './socials'

import { theme } from 'tailwind.config'

export default function Layout({
  children,
  subTheme,
  hideFooter = false,
  footerColor,
}: {
  children: React.ReactNode
  subTheme: SubTheme
  hideFooter?: boolean
  footerColor?: string
}) {
  const { primary, background } = subThemeMap[subTheme]
  return (
    <SubThemeProvider subTheme={subTheme}>
      <Head>
        <style>{`
        body,
        html {
          background-color: ${theme.colors[subTheme]};
        }
`}</style>
      </Head>
      <header>
        <NavBar />
      </header>
      <main className={`h-full w-full ${background} ${primary}`}>{children}</main>
      {!hideFooter && (
        <footer className="relative">
          <div
            className={`absolute bottom-9 left-[50%] translate-x-[-50%]
       ${footerColor ?? primary}`}
          >
            <Socials />
          </div>
        </footer>
      )}
    </SubThemeProvider>
  )
}
