import { useWeb3Context } from 'blockchain/Web3Context'
import Logo from 'components/logo'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useRef, useState } from 'react'
import { useSubTheme } from 'themes/subtheme'
import { WalletDropdown } from './WalletDropdown'

export const NavBar = () => {
  const { background, primary } = useSubTheme()
  const [menuOpen, setMenuOpen] = useState(false)
  const primaryAsBg = primary.replace('text', 'bg')

  const closeMenu = () => setMenuOpen(false)

  const ref = useRef(null)
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        closeMenu()
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])

  const { isConnected, address, ens, connectWallet, disconnectWallet } = useWeb3Context()

  const router = useRouter()
  const [showConfirm, setshowConfirm] = useState(false)
  const openConfirm = () => setshowConfirm(true)

  const handleConnect = async () => {
    await connectWallet()
    closeMenu()
  }

  const handleDisconnect = async () => {
    disconnectWallet()
    setshowConfirm(false)
    router.push('/')
    closeMenu()
  }

  const MobileRight = () => (
    <div className="ml-3 relative md:hidden">
      <div>
        <button
          type="button"
          id="user-menu-button"
          aria-expanded="false"
          aria-haspopup="true"
          onClick={() => setMenuOpen((isOpen) => !isOpen)}
        >
          <span className="sr-only">Open user menu</span>
          <div className="space-y-1.5">
            <div className={`w-6 h-0.5 ${primaryAsBg} `} />
            <div className={`w-6 h-0.5 ${primaryAsBg}`} />
            <div className={`w-6 h-0.5 ${primaryAsBg}`} />
          </div>
        </button>
      </div>

      {menuOpen && (
        <div
          className={`origin-top-right absolute right-0 mt-2 w-48 shadow-lg py-1 ${background} text-left
      ring-1 ring-black ring-opacity-5 focus:outline-none`}
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="user-menu-button"
          tabIndex={-1}
          ref={ref}
        >
          <div className="block px-4 py-2" role="menuitem" tabIndex={-1}>
            <WalletDropdown closeMenu={closeMenu} />
          </div>
          <Link href="/about">
            <a className="block px-4 py-2" role="menuitem" tabIndex={-1}>
              About
            </a>
          </Link>
        </div>
      )}
    </div>
  )

  return (
    <div
      className={`fixed top-0 z-10 w-full px-6 py-8 h-10 box-border ${background} ${primary} 
      flex flex-row justify-between items-center backdrop-blur-lg  bg-opacity-50`}
    >
      <Link href="/">
        <div className="cursor-pointer">
          <Logo />
        </div>
      </Link>
      <MobileRight />
      <DesktopRight />
    </div>
  )
}

const DesktopRight = () => (
  <div className="hidden flex-row gap-10 items-center md:flex">
    <Link href="/about">
      <a className="text-body1 font-sans no-underline font-medium">About</a>
    </Link>

    <WalletDropdown />
  </div>
)
