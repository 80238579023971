import * as React from 'react'

const Twitter = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        d="M24 4.309a9.843 9.843 0 0 1-2.828.775 4.939 4.939 0 0 0 2.165-2.724 9.857 9.857 0 0 1-3.127 1.195 4.924 4.924 0 0 0-8.39 4.491A13.979 13.979 0 0 1 1.67 2.9a4.9 4.9 0 0 0-.666 2.476c0 1.708.87 3.215 2.19 4.098a4.904 4.904 0 0 1-2.23-.616v.062a4.927 4.927 0 0 0 3.95 4.828 4.927 4.927 0 0 1-2.224.085 4.929 4.929 0 0 0 4.6 3.419 9.879 9.879 0 0 1-6.115 2.108c-.398 0-.79-.024-1.175-.07a13.936 13.936 0 0 0 7.548 2.213c9.057 0 14.01-7.503 14.01-14.01 0-.213-.005-.425-.015-.637A10.003 10.003 0 0 0 24 4.31Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="translate(0 2)" d="M0 0h24v19.504H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default Twitter
