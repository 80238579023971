import * as React from 'react'

const Discord = (props) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19.88 4.41A18.394 18.394 0 0 0 15.34 3a.069.069 0 0 0-.072.035c-.196.348-.413.803-.565 1.16a16.98 16.98 0 0 0-5.1 0 11.752 11.752 0 0 0-.573-1.16A.072.072 0 0 0 8.957 3a18.341 18.341 0 0 0-4.54 1.408.065.065 0 0 0-.03.026C1.496 8.755.704 12.968 1.092 17.13c.002.02.013.04.03.052a18.493 18.493 0 0 0 5.568 2.815.072.072 0 0 0 .079-.026c.429-.586.811-1.203 1.139-1.853a.07.07 0 0 0-.039-.098 12.19 12.19 0 0 1-1.74-.83.072.072 0 0 1-.007-.118c.117-.088.234-.179.346-.27a.069.069 0 0 1 .072-.01c3.65 1.666 7.602 1.666 11.209 0a.069.069 0 0 1 .073.008c.111.092.228.184.346.272.04.03.038.093-.006.119-.556.324-1.133.599-1.74.828a.071.071 0 0 0-.038.099c.334.649.717 1.267 1.138 1.852a.07.07 0 0 0 .078.027 18.432 18.432 0 0 0 5.578-2.815.071.071 0 0 0 .03-.052c.464-4.81-.78-8.99-3.298-12.694a.057.057 0 0 0-.03-.027ZM8.454 14.595c-1.099 0-2.004-1.01-2.004-2.248 0-1.24.888-2.248 2.004-2.248 1.125 0 2.022 1.018 2.004 2.248 0 1.239-.888 2.248-2.004 2.248Zm7.411 0c-1.099 0-2.004-1.01-2.004-2.248 0-1.24.888-2.248 2.004-2.248 1.125 0 2.022 1.018 2.004 2.248 0 1.239-.879 2.248-2.004 2.248Z"
      fill="currentColor"
    />
  </svg>
)

export default Discord
